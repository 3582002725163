




#wallets-container button {
    height: 44px !important;
    width: 100% !important;
    max-width: 240px !important;
    margin-bottom: 20px !important;
    border-radius: 5px !important;
}
