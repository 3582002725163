.auth {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #e5e9f2;
  z-index: 9999;
}

.auth .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

//header

.auth .auth-header,
.auth .auth-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 30px 0;
  font-size: 12px;
  font-weight: 500;
  color: #5a6679;
  //text-transform: uppercase;
}

@media (max-width: 767.98px) {
  .auth .auth-header,
  .auth .auth-footer {
    padding: 20px 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.auth .center {
  margin-left: auto;
  margin-right: auto;
}

//body

.auth .auth-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.auth .auth-box {
  background-color: #fff;
  border-radius: 0.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-shadow: 0 60px 60px 0 rgba(28, 44, 64, 0.15);
  box-shadow: 0 60px 60px 0 rgba(28, 44, 64, 0.15);
}

.auth .auth-box .auth-box-left,
.auth .auth-box .auth-box-right {
  padding: 80px;
  width: 520px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 767.98px) {
  .auth .auth-box .auth-box-left,
  .auth .auth-box .auth-box-right {
    padding: 40px 20px;
  }
}

@media (max-width: 991.98px) and (min-width: 768px) {
  .auth .auth-box .auth-box-left,
  .auth .auth-box .auth-box-right {
    padding: 60px 30px;
  }
}
