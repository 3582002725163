



.checkout-step-form .MuiStepContent-root {
    padding-right 0 !important
}

.checkout-step-form .MuiStepper-root {
    padding 0 20px !important
}

.checkout-step-form .MuiStepIcon-root.MuiStepIcon-completed, .checkout-step-form .MuiStepIcon-root.MuiStepIcon-active {
    color: #ee4034;
}


.checkout-step-form .MuiTabs-indicator {
    background-color: #3f51b5;
}

.checkout-step-form .MuiPaper-root {
    background-color transparent
}
