


.checkout-step-form .pickup-delivery-form .MuiListItem-root {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    margin-bottom 20px
}

.checkout-step-form .pickup-delivery-form .MuiListItem-root.Mui-selected {
    background-color: #ee4034 !important;
    color: white !important;
}
