.MuiDialog-paperScrollPaper {
  max-height: 100% !important;
}
.image-widget-item {
  background: no-repeat 50%;
  background-size: cover;
  border-radius: 3px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  overflow: hidden;
  margin: 0;
  vertical-align: top;
  display: inline-block;
}
.cart-content {
  flex: 1 1 auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  max-height: 50vh;
}
.cart-actions {
  flex: 0 0 auto;
}
.button-checkout {
  border-radius: 10px 0 0 10px !important;
  background-color: #c1caec !important;
  opacity: 0.8 !important;
}
.button-checkout:hover,
.button-checkout:active,
.button-checkout:focus {
  opacity: 1 !important;
}
@media screen and (max-width: 767px) {
  .cart-content .name h2 {
    font-size: 18px;
  }
}
/* Loader overlay for cart-actions */
.cart-actions-loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff; /* white background overlay */
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Spinner design */
.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3; /* light gray */
  border-top: 5px solid #d4454f; /* updated accent color */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
/* Keyframes for spinner rotation */
@-moz-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-o-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
