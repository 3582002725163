

.checkout-step-form .react-calendar {
    border: none
}


.checkout-step-form .react-calendar__tile--active,
.checkout-step-form .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
    background: #ee4034 !important
}

.checkout-step-form .react-calendar__tile--now {
    background #f7e886
}

.checkout-step-form .react-calendar__tile:disabled {
    background-color transparent
    opacity 0.3
}


.checkout-step-form .event-delivery-form .MuiListItem-root {
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    margin-bottom 20px
}



.checkout-step-form .event-delivery-form .MuiListItem-root.Mui-selected {
    background-color: #ee4034 !important;
    color: white !important;
    border none !important
}
