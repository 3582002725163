


.gift-item {

} 

.gift-item.disabled {
    opacity: 0.6;
}



.gift-item-selected {
    background-color: #efefef;
}

.gift-item.disabled:hover {
    background-color: transparent;
}